import React from "react";

const Container = ({ children, section, containerNoPadding, containerSlim, containerPb0, containerTitle, container50, container60, container40, text, gray = false, containerThin }) => {
    section = parseInt(section)
    let containerClass = 'container'
    // Clases para los container en la sección 100%
    if (section === 100) {
        if (containerSlim) {
            containerClass += ' container--slim'
        }
        if (containerPb0) {
            containerClass += ' container--pb0'
        }
    }
    if (section === 5050) {
        if (container50) {
            containerClass = ' container__50'
        }
        if (containerTitle) {
            containerClass += ' container--title'
        }
    }
    if (section === 6040) {
        if (container60) {
            containerClass = 'container__60'
        }
        if (container40) {
            containerClass = 'container__40'
        }
    }
    if (text) {
            containerClass = 'container__text'
    }
    // Clases genéricas para todos los container
    if (containerNoPadding) {
        containerClass += ' container--np'
    }
    if (gray) {
        containerClass += ' container--gray'
    }
    if (containerThin) {
        containerClass += ' container--thin'
    }
    return (
        <div className={containerClass}>
            {children}
        </div>
    )
}

export default Container