import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { EmailShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share"
import { notification } from "antd"

const Toolbar = ({ lang = "en", title, url, toolbarVertical = false }) => {
    const share = {
        "en": "Share",
        "es": "Compartir"
    }
    const data = useStaticQuery(graphql`query {
        site {
            siteMetadata {
                title
                twitterHandle
            }
        }
    }`)
    const twitterHandle = data.site.siteMetadata.twitterHandle

    const copyToClipboard = () => {
        if (typeof navigator === "undefined" || typeof navigator.clipboard === "undefined") {
            return
        }

        navigator.clipboard.writeText(url)
            .then(() => notification["success"]({ message: "Copiado al portapapeles!" })) //ToDo: reemplazar por un alert customizado
    }

    const toolbarClass = toolbarVertical ? 'toolbar toolbar--vertical' : 'toolbar'

    return (
        <div className={toolbarClass}>
            <span>{share[lang]}</span>

            <EmailShareButton body={title} url={url} resetButtonStyle={false} className="button-icon button-icon--sm button-icon--secondary">
                <i className="icon-mail"></i>
            </EmailShareButton>

            <WhatsappShareButton title={title} url={url} resetButtonStyle={false} className="button-icon button-icon--sm button-icon--secondary">
                <i className="icon-whatsapp"></i>
            </WhatsappShareButton>

            <LinkedinShareButton url={url} resetButtonStyle={false} className="button-icon button-icon--sm button-icon--secondary">
                <i className="icon-linkedin"></i>
            </LinkedinShareButton>

                <TwitterShareButton title={title} url={url} via={twitterHandle} resetButtonStyle={false} className="button-icon button-icon--sm button-icon--secondary">
                    <i className="icon-x"></i>
                </TwitterShareButton>

            <button className="button-icon button-icon--sm button-icon--secondary" onClick={copyToClipboard}>
                <i className="icon-link"></i>
            </button>
        </div>
    )
}

export default Toolbar
