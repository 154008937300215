import React from "react";

export const MainSection100 = ({sectionId = '', sectionColor, sectionType, slim = false, noPadding = false, noPaddingBottom = false, children, hero = false, frameGray = false, grayNograph = false, noFrame = false, sectionAlignLeft }) => {
    const sectionClass = ['main__section main__section--100']
    mountSectionTypeColor(sectionColor, sectionType, sectionClass)
    if (hero) {
        sectionClass.push('main__section--100-hero')
    }
    if (frameGray) {
        sectionClass.push('frame-gray')
    }
    if (grayNograph) {
        sectionClass.push('gray--nograph')
    }
    if (noFrame) {
        sectionClass.push('gray--noframe')
    }
    if (sectionAlignLeft) {
        sectionClass.push('main__section--100-align-left')
    }

    return (
        <div id={sectionId} className={sectionClass.join(' ')}>
            {children}
        </div>
    )
}

export const MainSection50 = ({sectionId = '', sectionColor, sectionType, sectionSize, overflow = false, invert = false, overflowInvert = false, children, noFrame = false}) => {
    const sectionClass = ['main__section main__section--50']
    mountSectionTypeColor(sectionColor, sectionType, sectionClass)
    if (sectionSize) {
        sectionClass.push(`main__section--50--${sectionSize}`)
    }
    if (overflow || overflowInvert) {
        sectionClass.length = 0
        sectionClass.push('main__section')
        sectionClass.push('main__section--50-overflow')
    }
    if (overflowInvert) {
        sectionClass.push('main__section--50-overflow-invert')
    }
    if (noFrame) {
        sectionClass.push('gray--noframe')
    }
    if (invert) {
        sectionClass.push('main__section--50-invert')
    }
    return (
        <div id={sectionId} className={sectionClass.join(' ')}>
            {children}
        </div>
    )
}

const mountSectionTypeColor = (sectionColor, sectionType, sectionClass) => {
    if (sectionColor && sectionType) {
        sectionClass.push(`${sectionType}-section ${sectionColor}`)
    } 
}