import React from 'react';

export const SocialMediaTecalis = ({size = 'sm', secondary = false, title}) => {
    let buttonClass = ['button', 'button-icon', `button-icon--${size}`]
    if (secondary) {
        buttonClass.push('button-icon--secondary')
    }
    buttonClass = buttonClass.join(' ')
	return (
		<div className="social-links">
			<h3>{title}</h3>
			<div className='button__wrapper'>
				<a className={buttonClass} href="https://twitter.com/tecalis" target="_blank" rel="me">
					<i className="icon-x"></i>
				</a>
				<a className={buttonClass} href="https://www.linkedin.com/company/tecalis" target="_blank" rel="me">
					<i className="icon-linkedin"></i>
				</a>
				<a className={buttonClass} href="https://www.youtube.com/c/Tecalis" target="_blank" rel="me">
					<i className="icon-youtube"></i>
				</a>
				<a className={buttonClass} href="https://www.facebook.com/tecalis" target="_blank" rel="me">
					<i className="icon-facebook"></i>
				</a>
				<a className={buttonClass} href="https://www.instagram.com/tecalis" target="_blank" rel="me">
					<i className="icon-instagram"></i>
				</a>
			</div>
		</div>
	);
};
